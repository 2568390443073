export const PortfolioData = [
	{
		content: "https://www.youtube.com/embed/zCvJdaQESyo",
		type: "avi",
		orientation: "landscape",
		title: "DULCE",
		description:
			"Video animado desarrollado junto con mis compañeros de equipo para nuestra tesis de la tecnicatura.",
		owner: "me",
		fav: true,
	},
	{
		content: "adv_orgullo",
		type: "dsg",
		orientation: "portrait",
		title: "Banner para marcha del Orgullo",
		description: "Diseño para concurso que se realizó para la campaña de difusión de la marcha del orgullo del 2023.",
		owner: "client",
		fav: false,
	},
	{
		content: "casa_campo",
		type: "jpg",
		orientation: "landscape",
		title: "Casa en el campo",
		description: "Fondo para un video animado de Dinnokivva.",
		owner: "client",
		fav: false,
	},
	{
		content: "cocina",
		type: "jpg",
		orientation: "landscape",
		title: "Cocina",
		description: "Fondo para un video animado de Dinnokivva.",
		owner: "client",
		fav: true,
	},
	{
		content: "cuarto_dinno",
		type: "jpg",
		orientation: "landscape",
		title: "Cuarto en la casa de campo",
		description: "Fondo para un video animado de Dinnokivva.",
		owner: "client",
		fav: false,
	},
	{
		content: "marca_alemana",
		type: "dsg",
		orientation: "landscape",
		title: "Lebensretter",
		description: "Ilustración para una campaña del emprendimiento alemán Tylo.",
		owner: "client",
		fav: false,
	},
	{
		content: "cafemoodboard",
		type: "dsg",
		orientation: "landscape",
		title: "Identidad corporativa - Café",
		description: "Moodboard de identidad corporativa para un proyecto de Kinesis Productions.",
		owner: "client",
		fav: false,
	},
	{
		content: "adv_aging",
		type: "dsg",
		orientation: "portrait",
		title: "Anuncio",
		description: "Diseño gráfico de anuncio orientado a su difusión en redes sociales.",
		owner: "client",
		fav: false,
	},
	{
		content: "adv_fitness",
		type: "dsg",
		orientation: "portrait",
		title: "Anuncio",
		description: "Diseño gráfico de anuncio orientado a su difusión en redes sociales.",
		owner: "client",
		fav: false,
	},
	{
		content: "adv_gym",
		type: "dsg",
		orientation: "portrait",
		title: "Anuncio",
		description: "Diseño gráfico de anuncio orientado a su difusión en redes sociales.",
		owner: "client",
		fav: false,
	},
	{
		content: "adv_ingles",
		type: "dsg",
		orientation: "portrait",
		title: "Anuncio",
		description: "Diseño gráfico de anuncio orientado a su difusión en redes sociales.",
		owner: "client",
		fav: false,
	},
	{
		content: "adv_padres",
		type: "dsg",
		orientation: "portrait",
		title: "Anuncio",
		description: "Diseño gráfico de anuncio orientado a su difusión en redes sociales.",
		owner: "client",
		fav: false,
	},
	{
		content: "adv_potencia",
		type: "dsg",
		orientation: "portrait",
		title: "Anuncio",
		description: "Diseño gráfico de anuncio orientado a su difusión en redes sociales.",
		owner: "client",
		fav: false,
	},
	{
		content: "adv_ruth",
		type: "dsg",
		orientation: "portrait",
		title: "Anuncio",
		description: "Diseño gráfico de anuncio orientado a su difusión en redes sociales.",
		owner: "client",
		fav: false,
	},
	{
		content: "https://www.youtube.com/embed/ErvauLSKTYg",
		type: "avi",
		orientation: "landscape",
		title: "Let's See The Morning Routine of Dinnokivva!",
		description: "Video educativo infantil para el canal de Youtube de Dinnokivva.",
		owner: "client",
		fav: false,
	},
	{
		content: "frutas",
		type: "jpg",
		orientation: "portrait",
		title: "Toon Fruits",
		description: "Ilustraciones para un video educativo del canal de youtube de Dinokkivva.",
		owner: "client",
		fav: false,
	},
	{
		content: "https://www.youtube.com/embed/N2-2_DGxf_A",
		type: "avi",
		orientation: "landscape",
		title: "Learn School Supplies with Dinnokivva!",
		description: "Video educativo infantil para el canal de Youtube de Dinnokivva.",
		owner: "client",
		fav: false,
	},
	{
		content: "https://www.youtube.com/embed/LrXyBWzZ2Pg",
		type: "avi",
		orientation: "landscape",
		title: "Learn more about dinosaurs with Dinnokivva!",
		description: "Video educativo infantil para el canal de Youtube de Dinnokivva.",
		owner: "client",
		fav: false,
	},
	{
		content: "https://www.youtube.com/embed/tvD9TK0Vg88",
		type: "avi",
		orientation: "landscape",
		title: "Musical Adventures with Dinnokivva: Learn Instruments",
		description: "Video educativo infantil para el canal de Youtube de Dinnokivva.",
		owner: "client",
		fav: false,
	},
	{
		content: "https://www.youtube.com/embed/FF5xR1oPFIw",
		type: "avi",
		orientation: "landscape",
		title: "Head, Shoulders, Knees and Toes Song with Dinnokivva and friends!",
		description: "Video educativo infantil para el canal de Youtube de Dinnokivva.",
		owner: "client",
		fav: false,
	},
	{
		content: "https://www.youtube.com/embed/0IRo2GEr3Bk",
		type: "avi",
		orientation: "landscape",
		title: "Dinnokivva's Zooming Adventure: Discover Transportation Wonders",
		description: "Video educativo infantil para el canal de Youtube de Dinnokivva.",
		owner: "client",
		fav: false,
	},
	{
		content: "vegetales",
		type: "jpg",
		orientation: "portrait",
		title: "Toon Vegetables",
		description: "Ilustraciones para un video educativo del canal de youtube de Dinokkivva.",
		owner: "client",
		fav: false,
	},
	{
		content: "https://www.youtube.com/embed/K2xjdT_IvNc",
		type: "avi",
		orientation: "landscape",
		title: "Learn the Alphabet with Dinnokivva!",
		description: "Video educativo infantil para el canal de Youtube de Dinnokivva.",
		owner: "client",
		fav: false,
	},
	{
		content: "aranas",
		type: "jpg",
		orientation: "landscape",
		title: "Spiders",
		description: "Clean up para un cuento interactivo.",
		owner: "client",
		fav: false,
	},
	{
		content: "https://www.youtube.com/embed/uXq67l4roSY",
		type: "avi",
		orientation: "landscape",
		title: "Meet the Dinosaurs with Dinnokivva!",
		description: "Video educativo infantil para el canal de Youtube de Dinnokivva.",
		owner: "client",
		fav: false,
	},
	{
		content: "utiles",
		type: "jpg",
		orientation: "portrait",
		title: "Toon School Supplies",
		description: "Ilustraciones para un video educativo del canal de youtube de Dinokkivva.",
		owner: "client",
		fav: true,
	},
	{
		content: "bar",
		type: "jpg",
		orientation: "landscape",
		title: "Bar",
		description: "Fondo para un proyecto final de animación.",
		owner: "me",
		fav: false,
	},
	{
		content: "https://www.youtube.com/embed/D_fREKgnXLA",
		type: "avi",
		orientation: "landscape",
		title: "Learn the Farm Animals with Dinnokivva!",
		description: "Video educativo infantil para el canal de Youtube de Dinnokivva.",
		owner: "client",
		fav: false,
	},
	{
		content: "https://www.youtube.com/embed/GncuYuQbRPE",
		type: "avi",
		orientation: "landscape",
		title: "Learn the Numbers!",
		description: "Video educativo infantil para el canal de Youtube de Dinnokivva.",
		owner: "client",
		fav: false,
	},
	{
		content: "castillo",
		type: "jpg",
		orientation: "landscape",
		title: "Castillo",
		description: "Ilustración para el Final de la materia Color. Inspirado en el estilo de Disney.",
		owner: "me",
		fav: false,
	},
	{
		content: "https://www.youtube.com/embed/fHxoahabUcM",
		type: "avi",
		orientation: "landscape",
		title: "Make a Wish",
		description: "Video musical animado para la cantante Charlene.",
		owner: "client",
		fav: false,
	},
	{
		content: "eevee",
		type: "jpg",
		orientation: "portrait",
		title: "Eevee",
		description: "Fanart de Pokémon.",
		owner: "me",
		fav: false,
	},
    {
		content: "jigglypuff",
		type: "jpg",
		orientation: "portrait",
		title: "Jigglypuff sobre su marcador",
		description:
			"Fanart de Pokémon.",
		owner: "me",
		fav: true,
	},
	{
		content: "chica2",
		type: "jpg",
		orientation: "portrait",
		title: "",
		description: "Ilustración original.",
		owner: "me",
		fav: false,
	},
	{
		content: "https://www.youtube.com/embed/K2_m8tLj0rM",
		type: "avi",
		orientation: "landscape",
		title: "Couple",
		description: "Fragmento de una animación creada para el proyecto final de la materia Animación Tradicional.",
		owner: "me",
		fav: false,
	},
	{
		content: "chico",
		type: "jpg",
		orientation: "portrait",
		title: "",
		description: "Ilustración original.",
		owner: "me",
		fav: false,
	},
	{
		content: "https://www.youtube.com/embed/QMz4Ijoh6Js",
		type: "avi",
		orientation: "landscape",
		title: "Head Over Heels",
		description: "Clips en los que participé en el coto animado Head Over Heels.",
		owner: "client",
		fav: false,
	},
	{
		content: "chikorita",
		type: "jpg",
		orientation: "portrait",
		title: "Chikorita",
		description: "Fanart de Pokémon.",
		owner: "me",
		fav: false,
	},
	{
		content: "conejo",
		type: "jpg",
		orientation: "portrait",
		title: "",
		description: "Ilustración original.",
		owner: "me",
		fav: false,
	},
	{
		content: "cuarto",
		type: "jpg",
		orientation: "landscape",
		title: "Habitación",
		description: "Fondo para un proyecto final de animación.",
		owner: "me",
		fav: false,
	},
	{
		content: "https://www.youtube.com/embed/cty7sNbx7mk",
		type: "avi",
		orientation: "landscape",
		title: "Vegetarian",
		description: "Animación cutout para final de la materia.",
		owner: "me",
		fav: false,
	},
	{
		content: "cuento",
		type: "jpg",
		orientation: "portrait",
		title: "Little bugs",
		description: "Clean up para un cuento interactivo.",
		owner: "client",
		fav: false,
	},
	{
		content: "drag",
		type: "jpg",
		orientation: "portrait",
		title: "",
		description: "Ilustración original.",
		owner: "me",
		fav: false,
	},
	{
		content: "https://www.youtube.com/embed/nF40llPb-74",
		type: "avi",
		orientation: "landscape",
		title: "ARTE",
		description: "Animación de texto para un trabajo práctico de la tecnicatura.",
		owner: "me",
		fav: false,
	},
	{
		content: "flores",
		type: "jpg",
		orientation: "portrait",
		title: "",
		description: "Ilustración original.",
		owner: "me",
		fav: false,
	},
	{
		content: "gengar",
		type: "jpg",
		orientation: "portrait",
		title: "Gengar asomando desde su Pokeball",
		description: "Fanart de Pokémon.",
		owner: "me",
		fav: false,
	},
	{
		content: "hongos",
		type: "jpg",
		orientation: "portrait",
		title: "",
		description: "Ilustración original.",
		owner: "me",
		fav: false,
	},
	{
		content: "isla",
		type: "jpg",
		orientation: "portrait",
		title: "Ilustración original",
		description: "Inspirada en el estilo de la serie animada Isla del Drama.",
		owner: "me",
		fav: true,
	},
	{
		content: "mansion",
		type: "jpg",
		orientation: "landscape",
		title: "",
		description: "Ilustración original.",
		owner: "me",
		fav: false,
	},
	{
		content: "mascaras",
		type: "jpg",
		orientation: "portrait",
		title: "",
		description: "Ilustración original.",
		owner: "me",
		fav: false,
	},
	{
		content: "oso",
		type: "jpg",
		orientation: "portrait",
		title: "Bossy the Bear",
		description: "Clean up para un cuento interactivo.",
		owner: "client",
		fav: false,
	},
	{
		content: "https://www.youtube.com/embed/UrKJ0JC0ivI",
		type: "avi",
		orientation: "landscape",
		title: "The Baddest",
		description: "Animación de texto sincronizado para un trabajo práctico de la tecnicatura.",
		owner: "me",
		fav: false,
	},
	{
		content: "policias",
		type: "jpg",
		orientation: "portrait",
		title: "Uniformes de las fuerzas armadas,",
		description: "Ilustraciones para un libro de la Policía Federal Argentina.",
		owner: "client",
		fav: false,
	},
	{
		content: "polillas",
		type: "jpg",
		orientation: "portrait",
		title: "",
		description: "Ilustración original.",
		owner: "me",
		fav: false,
	},
	{
		content: "rana",
		type: "jpg",
		orientation: "portrait",
		title: "",
		description: "Ilustración original.",
		owner: "me",
		fav: false,
	},
	{
		content: "reno",
		type: "jpg",
		orientation: "portrait",
		title: "",
		description: "Ilustración original.",
		owner: "me",
		fav: false,
	},
	{
		content: "tocador",
		type: "jpg",
		orientation: "landscape",
		title: "Tocador",
		description: "Fondo para un proyecto final de animación.",
		owner: "me",
		fav: false,
	},
];
